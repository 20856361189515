import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {JiraTask} from "./jira-task";
import {JiraTasksService} from "./jira-tasks.service";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {ToasterService} from "angular2-toaster";
import {NgForm} from "@angular/forms";
import {Observable} from "rxjs";
import "rxjs-compat/add/observable/forkJoin";
declare var $: any;

@Component({
  selector: 'app-jira-task',
  templateUrl: './jira-task.component.html',
  styleUrls: ['./jira-task.component.css']
})
export class JiraTaskComponent implements OnInit {

  jiraTask: JiraTask;
  files: File[];
  issueTypes: string[];
  environments: string[]
  investigatingTeams: string[]
  jiraTaskURl: string;

  @ViewChild('itemForm', {static: false})
  itemForm: any;
  @ViewChild('filePicker', {static: true})
  filePicker: ElementRef;


  @BlockUI() blockUI: NgBlockUI;

  constructor(public jiraTasksService: JiraTasksService,
              public toasterService: ToasterService) {
  }

  ngOnInit() {
    console.log('Loaded page!!');
    this.resetForm();
    this.blockUI.start();
    this.jiraTasksService.getIssueEnvironments().subscribe((environments: string[]) => {
      this.environments = environments;
      this.blockUI.stop();
    }, (error => {
      this.blockUI.stop();
      if (error.error) {
        this.toasterService.pop('error', 'Error', error.error.message);
      } else {
        this.toasterService.pop('error', 'Error', error.message);
      }
    }));


     this.jiraTasksService.getIssueTypes().subscribe((issueTypes: string[]) => {
       this.issueTypes = issueTypes;
       this.blockUI.stop();
     }, (error => {
       this.blockUI.stop();
       if (error.error) {
         this.toasterService.pop('error', 'Error', error.error.message);
       } else {
         this.toasterService.pop('error', 'Error', error.message);
       }
     }));

    this.jiraTasksService.getInvestigatingTeam().subscribe((investigatingTeams: string[]) => {
      this.investigatingTeams = investigatingTeams;
      this.blockUI.stop();
    }, (error => {
      this.blockUI.stop();
      if (error.error) {
        this.toasterService.pop('error', 'Error', error.error.message);
      } else {
        this.toasterService.pop('error', 'Error', error.message);
      }
    }));

    console.log('investigatingTeams: ' + this.investigatingTeams);

  }

  attachFiles(e) {
    this.files = new Array();
    for (var i = 0; i < e.target.files.length; i++) {
      this.files.push(e.target.files[i]);
    }
  }

  save(form: NgForm) {
    const that = this;
    this.blockUI.start();
    this.jiraTasksService.save(this.jiraTask).subscribe((taskId: string) => {
      if (this.files) {
        let observables: Observable<any>[] = [];
        this.files.forEach((blob: File) => {
          observables.push(this.jiraTasksService.uploadAttachment(blob, taskId));
        });

        Observable.forkJoin(observables)
          .subscribe(dataArray => {
            // All observables in `observables` array have resolved and `dataArray` is an array of result of each observable
            $(that.filePicker.nativeElement).fileinput('clear');
          });
      }
      this.toasterService.pop('success', 'Saved', 'Issue has been created. ' + taskId);
      this.jiraTaskURl = 'https://vodacom.atlassian.net/browse/' + taskId;
      this.resetForm();
      form.resetForm();
      this.blockUI.stop();
    }, (error => {
      this.blockUI.stop();
      if (error.error) {
        this.toasterService.pop('error', 'Error', error.error.message);
      } else {
        this.toasterService.pop('error', 'Error', error.message);
      }
    }));
  }

  onChangePriority(e){
     this.jiraTask.priority = e.target.value;
  }

  getjiraTaskURl() {
    return this.jiraTaskURl;
  }
  displayJiraTaskURl() {
    return (this.jiraTaskURl == null);
  }

  resetForm() {
    this.jiraTask = new JiraTask();
    this.jiraTask.issueType = "-1";
    this.jiraTask.environment = "-1";
    this.jiraTask.investigatingTeam = "-1";
       this.jiraTask.priority = "10003";
    this.files = new Array();
  }

  isFormValid() {
    return this.itemForm && this.itemForm.valid && this.jiraTask.issueType !== "-1" &&  this.jiraTask.environment !== "-1" &&this.jiraTask.investigatingTeam !== "-1";
  }

    protected readonly onchange = onchange;
}
