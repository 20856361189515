import {Directive, ElementRef, OnInit} from '@angular/core';
declare var $: any;
@Directive({
  selector: '[appFileinput]'
})
export class FileinputDirective implements  OnInit{

  constructor(private el: ElementRef) {
  }

  ngOnInit(): void {
    $(this.el.nativeElement).fileinput({
      theme: 'fas',
      dropZoneEnabled: false,
      mainClass: "input-group"
    });
  }
}
